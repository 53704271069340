import { render, staticRenderFns } from "./CompetitionFilterArtLayout.vue?vue&type=template&id=f3e64e24&scoped=true"
import script from "./CompetitionFilterArtLayout.vue?vue&type=script&lang=js"
export * from "./CompetitionFilterArtLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3e64e24",
  null
  
)

export default component.exports