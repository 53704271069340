<template>
  <div class="ifmf_wrap pa0 ma0">
    <div class="item_modal_filter_wrap ma0">
      <div class="search_item_wrap">
        <div class="filter_category_box">
<!--          <select id="category" value="subject" class="w_content select">-->
<!--            <option value="subject">{{$t('subject')}}</option>-->
<!--            <option value="hashtag">{{ $t('hashtag') }}</option>-->
<!--            <option value="organizer">{{ $t('organizer') }}</option>-->
<!--          </select>-->
          <el-select id="category" v-model="searchOption" class="my_filter select"
                     popper-class="category_select"
                     :popper-append-to-body="false">
            <el-option :value="'ina'" :label="$t('subject')"></el-option>
            <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
            <el-option :value="'orni'" :label="$t('organizer')"></el-option>
          </el-select>
        </div>
        <div class="search_input">
          <input :placeholder="`${$t('st')}`" v-model="searchMsg" @keypress.enter="searchSubmit()"></input>
          <button class="search_btn" @click="searchSubmit()"><span>{{$t('search')}}</span><img src="@/assets/image/main/search_btn.png"></button>
        </div>
        <div class="list_type">
          <button class="list_type_btn " :class="{ listType : listChange === false}" @click="listTypeChange"></button>
        </div>
        <div class="end_filter">
          <el-popover
              placement="bottom"
              width="435"
              trigger="click">
            <el-button slot="reference" class="filter_btn two" @click="changeCheck('filterEndCheck')"></el-button>
            <div class="popup_bg">
              <div class="popup_inner">
                <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                <div class="inner_middle">
                  <div class="sub_title">카테고리</div>
                  <div class="edition_wrap">
                    <el-checkbox v-model="artCheck" @click="changeCheck('artCheck')">
                      {{ $t('art') }}
                    </el-checkbox>
                    <el-checkbox v-model="collectionCheck" @click="changeCheck('collectionCheck')">
                      {{ $t('collections') }}
                    </el-checkbox>
                    <el-checkbox v-model="photoCheck" @click="changeCheck('photoCheck')">
                      {{ $t('picture') }}
                    </el-checkbox>
                    <el-checkbox v-model="musicCheck" @click="changeCheck('musicCheck')">
                      {{ $t('music') }}
                    </el-checkbox>
                    <el-checkbox v-model="videoCheck" @click="changeCheck('videoCheck')">
                      {{ $t('video') }}
                    </el-checkbox>
                  </div>
                  <div class="sub_title">{{ $t('status') }}</div>
                  <div class="edition_wrap">
                    <el-checkbox v-model="proceedCheck" @click="changeCheck('proceedCheck')">
                      {{ $t('scheduled_to_proceed') }}
                    </el-checkbox>
                    <el-checkbox v-model="proceedingCheck" @click="changeCheck('proceedingCheck')">
                      {{ $t('in_process2') }}
                    </el-checkbox>
                    <el-checkbox v-model="underReviewCheck" @click="changeCheck('underReviewCheck')">
                      {{ $t('under_review') }}
                    </el-checkbox>
                    <el-checkbox v-model="presentationCheck" @click="changeCheck('presentationCheck')">
                      {{ $t('p_completed') }}
                    </el-checkbox>
                  </div>
                  <div class="sub_title">{{ $t('price2') }}</div>
                  <div class="price_wrap mb10">
                    <el-input class="el_price_input" type="number" v-model="priceInput[0]"></el-input>
                    <span>
                            Mg ~
                            </span>
                    <el-input class="el_price_input" type="number" v-model="priceInput[1]"></el-input>
                    <span>
                            Mg
                            </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrapper_bottom" @click="changeCheck('filterEndCheck')">
              <button class="">{{$t('apply2')}}</button>
            </div>
          </el-popover>
        </div>
<!--        <div class="filter_sort_box">-->
<!--          <select id="sort_select" value="up
date" class="w_content select">-->
<!--            <option value="update">{{$t('b_update')}}</option>-->
<!--            <option value="view">{{ $t('b_enter') }}</option>-->
<!--            <option value="attention">{{ $t('b_like') }}</option>-->
<!--            <option value="high_price">{{ $t('priceDesc') }}</option>-->
<!--            <option value="row_price">{{ $t('priceAsc') }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="filter_sort_box">
          <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                     @change="sortChange(sortOption)">
            <el-option :value="'i.updated_at'" :label="$t('b_update')"></el-option>
            <el-option :value="'im_real_hit'" :label="$t('b_enter_m')"></el-option>
            <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
            <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
            <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
  name: "CompetitionFilterArtLayout",
  mixins: [],
  components: {

  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'i.updated_at',
      searchInput: '',
      filterStatus: [],
      filterEdition: [],
      filterType: [],
      priceInput: ['',''],
      popoverStatus: false,

      listChange: false,

      artCheck: false,
      collectionCheck: false,
      photoCheck: false,
      musicCheck: false,
      videoCheck: false,

      proceedCheck: false,
      proceedingCheck: false,
      underReviewCheck: false,
      presentationCheck: false,

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    returnCommas(x = 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openModal(name){
      this.$modal.show(name);
    },
    searchSubmit() {
      // 검색 입력 후 ,
      this.searchCheck = true;
      this.$router.push('/search');
    },
    listTypeChange(){
      this.listChange = !this.listChange;
      EventBus.$emit('changeListType');
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    changeCheck(dataName) {
      this[dataName] = !this[dataName];
      if(this[dataName] ) {
        this[dataName] = true;
      } else {
        this[dataName] = false;
      }
      if(this[dataName]) {
        this.btnCheck = false;
      }
    },
  },
  watch: {
  },
}
</script>

<style scoped>

</style>
